import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CssBaseline from "@mui/material/CssBaseline";
import Timelog from "../Timelog";

const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{mt:20}}>
            Copyrights © {new Date().getFullYear()} All Rights Reserved by Fjolskylduland <br/>
            © Designed and developed by <Link target="_blank" color="inherit" href="https://arda.is/">ARDA.IS</Link>
        </Typography>
    );
}

export default function App() {
    return (
        <Container maxWidth="xl">
            <CssBaseline/>
            <Box>
                <Timelog/>
                <Copyright/>
            </Box>
        </Container>
    );
}