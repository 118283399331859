import {useState} from 'react';
import './style.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ErrorMessage from "../../components/ErrorMessage";
import Grid from "@mui/material/Grid";
import Loader from "../../components/Loader";
import ReactInputVerificationCode from "react-input-verification-code";
import SuccessMessage from "../../components/SuccessMessage";
import Typography from '@mui/material/Typography';
import axios from 'axios';

const Timelog = () => {
    const [employee, setEmployee] = useState(false);
    const [event, setEvent] = useState(false);

    const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);
    const [isEventLoading, setIsEventLoading] = useState(false);

    const [employeeError, setEmployeeError] = useState('');
    const [eventError, setEventError] = useState('');

    const [employeeRawCodeValue, setEmployeeRawCodeValue] = useState('');
    const [employeeCodeValue, setEmployeeCodeValue] = useState('');

    const [checkInStateValue, setCheckInStateValue] = useState(false);
    const [checkOutStateValue, setCheckOutStateValue] = useState(false);

    const searchEmployee = async(event) => {
        setIsEmployeeLoading(true);
        setEmployee(false);

        setEmployeeRawCodeValue(event);
        setEmployeeCodeValue('');

        setCheckInStateValue(false);
        setCheckOutStateValue(false);

        if (event.length > 1) {
            setEmployeeError('');
            setEvent(false);
        }

        if (event.length === 4) {
            try {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_URL}/employee/` + event,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    },
                );

                setEmployeeError('');
                setEmployee(data);

                setEmployeeCodeValue(data.code);

                setCheckInStateValue(true);
                setCheckOutStateValue(true);
            } catch (err) {
                let employeeErrorMessage = '';
                if (undefined === err.response.data) {
                    employeeErrorMessage = err.message;
                } else {
                    employeeErrorMessage = err.response.data.message;
                }
                setEmployeeError(employeeErrorMessage);
                setEmployeeCodeValue('');
                setEmployeeRawCodeValue('');
            } finally {
                setIsEmployeeLoading(false);
            }
        } else {
            setIsEmployeeLoading(false);
        }
    };

    const sendEventToTimelog = async (event) => {
        setIsEventLoading(true);
        setEventError('');
        setEvent(false);

        let type = event.target.getAttribute('data-type');

        try {
            const {data} = await axios.post(
                `${process.env.REACT_APP_API_URL}/event`,
                {
                    code: employeeCodeValue,
                    type: type
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );

            setEvent(data);
        } catch (err) {
            setEventError(err.message);
        } finally {
            setIsEventLoading(false);
            setEmployee(false);
            setEmployeeCodeValue('');
            setEmployeeRawCodeValue('');
        }
    };

    return (
        <div id={"timelogContent"}>
            <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }} >
                    <Box sx={{marginBottom: 3}}>
                        {!isEmployeeLoading && employee && (
                            <img src={employee.photo}
                                 loading="lazy"
                                 className={"employee-photo"}
                            />
                        )}
                        {!isEmployeeLoading && !employee && (
                            <img src="/images/logo-192x192.png"
                                 loading="lazy"
                                 className={"employee-photo"}
                            />
                        )}
                    </Box>

                    {!isEmployeeLoading && employee && (
                        <Typography component="h3" variant="h4">
                            {employee.title}
                        </Typography>
                    )}
                    {!isEmployeeLoading && !employee && (
                        <Typography component="h3" variant="h3">
                            Enter your code
                        </Typography>
                    )}
                    {isEmployeeLoading && <Loader/>}

                    <Box component="form"
                         noValidate
                         sx={{
                             mt: 3,
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center'
                         }}
                    >
                        <div className="custom-styles">
                            {employeeError && <ErrorMessage message={employeeError} />}
                            <ReactInputVerificationCode
                                onChange={searchEmployee}
                                value={employeeRawCodeValue}
                                placeholder={''}
                                type="text"
                                length={4}
                                name="employee_raw_code"
                                autoFocus={true}/>
                            <input type="hidden" id={'employeeCode'} name={'employee_code'} value={employeeCodeValue}></input>
                        </div>

                        <Grid container spacing={4} className={'button-block'}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    // sx={{mt: 10, mb: 2}}
                                    color="success"
                                    className="btn-check btn-in"
                                    onClick={sendEventToTimelog}
                                    data-type="in"
                                    disabled={!checkInStateValue}
                                >
                                    In
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    // sx={{mt: 10, mb: 2}}
                                    className="btn-check btn-out"
                                    onClick={sendEventToTimelog}
                                    data-type="out"
                                    disabled={!checkOutStateValue}
                                >
                                    Out
                                </Button>
                            </Grid>
                        </Grid>

                        <Box sx={{marginTop: 5}}>
                            {isEventLoading && <Loader/>}
                            {eventError && <ErrorMessage message={eventError} />}
                            {!eventError && event && <SuccessMessage message={event.datetime + ': #' + event.employee_code + ' -> ' + event.event_type} />}
                        </Box>
                    </Box>
                </Box>
            </div>
    );
};

export default Timelog;